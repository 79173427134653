.valuePriceCardContainer {
    display: flex;
    justify-content: center;
    gap: 16px; /* Adjust the gap as needed */
}

.infoButton {
    border: 2px solid #000000;
    color: rgb(0, 0, 0);
    background-color: rgba(241, 164, 63, 0.699);
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    padding: 0 20px;
    border-radius: 100px;
    border: none ;
}
.allocationStaking {
    margin: 0 5%; /* Adjust the percentage based on your preference */
    margin-bottom: 5vh;
    .pageTitle {
        display: flex;
        flex-direction: column;
        align-items: center;  /* Center horizontally */
        justify-content: center;  /* Center vertically */
        height: 10%;  /* Adjust the height as needed */
        margin-bottom: 5vh;
        .mainText {
            margin-top: 8vh;
            font-family: 'Space Grotesk', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 4vh;
            color: #2f2f2f;
            

            div {
                vertical-align: middle;
                svg{
                    vertical-align: middle;
                    color: rgba(255, 255, 255, 0.351);
                    margin-left: 0.4em;
                    font-size: 3vh;
                    cursor: pointer;
                    transition: all 1s ease-in-out;
                    margin-bottom: 0.2em;
                }

                svg:hover{
                    color: white;
                }

            }
        }

        .infoButton {
            width: 30px;
            height: 30px;
            background-color: rgb(203, 203, 203);
            border: 2px solid #ffffff;
            box-sizing: border-box;
            border-radius: 50%;
            text-align: center;
            color: white;
            font-size: 12px;
            cursor: pointer;
            transition: 0.5s;
        }

        .infoButton:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }

    .vpCard {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 10px;
        position: relative;
        width: 100%;
        max-width: 600px;
        margin: 0 auto; /* Center the card horizontally */
        margin-bottom: 20px;
        background-color: #ffffff;
      }
      .vpCardblack {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        color: #303030;
        border-radius: 10px;
        position: relative;
        width: 100%;
        max-width: 600px;
        margin: 0 auto; /* Center the card horizontally */
        margin-bottom: 20px;
        background: rgb(223,171,126);
        background: radial-gradient(circle, rgba(223,171,126,0.2190126050420168) 0%, rgba(250,250,250,0) 49%, rgba(223,171,126,0.571953781512605) 100%);         box-shadow: 0 1px 1px rgba(0,0,0,.03), 0 4px 16px -8px rgba(0,0,0,.09);
         width: 100%;
    
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
      }

      .vpCardstake {

    
        border: none;
        border-radius: 10px;
        position: relative;
        width: 100%;
        max-width: 500px;
        margin: 0 auto; /* Center the card horizontally */
        margin-bottom: 20px;
        background-color: #ffffff;
        padding: 20px;
      }

      .vpCarwithdraw {

    
        border: none;
        border-radius: 10px;
        position: relative;
        width: 100%;
        max-width: 700px;
        margin: 0 auto; /* Center the card horizontally */
        margin-bottom: 20px;
        background-color: #ffffff;
        padding: 20px;
      }
      
      
      .row {
        display: flex;
        justify-content: space-between;
      }
      
      .dataTitle {
        /* Add any styles for individual data items */
        font-weight: 500;
        font-size: 12px;
        color: #8a8a8a;
      }
      .dataItem {
        /* Add any styles for individual data items */
        padding: 15px;
        font-weight: 600;
        font-size: 16px;
      }
    .vpCardContainer {
        display: flex;
        justify-content: space-between;  // or use 'space-around' for some spacing
    }
    .pageContent {
        margin-top: 10vh;
        
        .infoCards {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        .stakingCards {
            display: flex;
            width: 100%;
            margin-bottom: 3em;
            justify-content: space-between;
        }
    }
}

@media (max-width: 900px) {
    .allocationStaking {
        .pageTitle {
            .mainText {
                max-width: 80vw;
                // width: 752px;
                color: black;
                font-family: 'gotham-bold', sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 4vh;
                height: 1em;
                margin-top: 28vw;
                margin-bottom: 3em;
            }

            .infoButton {
                border: 2px solid #000000;
                color: rgb(0, 0, 0);
                margin-top: 4rem;
                background-color: rgb(203, 203, 203);
            }
        }

        .vpCard {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 4vh;
        }

        .pageContent {
            flex-direction: column;
            margin-top: 5vh;
        }
    }
}

@media (max-width: 1440px) {
    .allocationStaking {
        .pageTitle {
            .mainText {
                font-size: 3vw;
            }
        }

        .vpCard {
            margin-top: 4vh;
        }
    }
}

@media (max-width: 1000px) and (orientation: portrait) {
    .allocationStaking {
        margin-top: -20px;
        .pageTitle {
            .mainText {
                color: white;
                font-size: 22pt;
                max-width: 100%;
            }

            .infoButton {
                color: white;
                border-color: white;
                width: fit-content;
                padding-left: 3em;
                padding-right: 3em;
                font-size: 14pt;
                line-height: 14pt;
                height: auto;
                padding-top: 0.5em;
                padding-bottom: 0.5em;
                background-color: rgb(203, 203, 203);

            }

        }

        .vpCard {
            margin-top: 15vh;
        }

        .pageContent {
            margin-top: 1em;

            .infoCards {
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }

            .stakingCards {
                display: flex;
                width: 100%;
                margin-bottom: 3em;
                justify-content: space-between;
                flex-direction: column;
            }
        }
    }
}
@media (max-width: 600px) {
    .mainText {
        font-size: 16px;
        margin-top: 20px;
    }
    .row {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .infoButton {

        font-size: 14px;
        margin-top: 10px;
    }

    .vpCard {
        margin-top: 20px;
    }

    .pageContent {
        margin-top: 30px;
    }
}