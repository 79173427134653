.TermsAndConditions{
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 20px;
    margin: 5vh 5% 0;
    
    header{
        h1{
            margin-top: 6vh;
            color: rgb(0, 0, 0) !important;
            font-size: 3.6vw;
        }
        
        margin-bottom: 10vh;
    }

    main{
        color: rgb(27, 27, 27) !important;
        font-size: 1.4vw;
    }
}
