@page {
    margin-bottom: 5pt;
    margin-top: 5pt
    }

.block_ {
    display: block;
    font-size: 1.125em;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 8pt;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0
    }
.block_1 {
    display: block;
    line-height: 1.2;
    margin-bottom: 8pt;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0
    }
.block_2 {
    display: list-item;
    line-height: 1.2;
    margin-bottom: 8pt;
    margin-left: 36pt;
    padding-bottom: 0;
    padding-top: 0
    }
.block_3 {
    display: list-item;
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 36pt;
    padding-bottom: 0;
    padding-top: 0
    }
.block_4 {
    display: list-item;
    line-height: 1.2;
    margin-bottom: 8pt;
    margin-left: 72pt;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0
    }
.block_5 {
    display: list-item;
    line-height: 1.2;
    margin-bottom: 8pt;
    margin-left: 72pt;
    padding-bottom: 0;
    padding-top: 0
    }
.block_6 {
    display: list-item;
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 72pt;
    padding-bottom: 0;
    padding-top: 0
    }
.block_7 {
    display: list-item;
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 108pt;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0
    }
.block_8 {
    display: list-item;
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 72pt;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0
    }
.block_9 {
    display: list-item;
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 36pt;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0
    }
.block_10 {
    display: list-item;
    line-height: 1.2;
    margin-bottom: 8pt;
    margin-left: 36pt;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0
    }
.block_11 {
    display: block;
    font-family: serif;
    font-size: 0.75em;
    line-height: 1.08;
    margin-bottom: 8pt;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0
    }
.calibre {
    display: block;
    font-family: "Calibri", sans-serif;
    font-size: 1em;
    margin-bottom: 0;
    margin-left: 5pt;
    margin-right: 5pt;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0
    }
.calibre1 {
    font-weight: bold;
    line-height: 1.2
    }
.list_ {
    display: block;
    list-style-type: decimal;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-top: 0
    }
.list_1 {
    display: block;
    list-style-type: lower-alpha;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-top: 0
    }
.list_2 {
    display: block;
    list-style-type: lower-roman;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-top: 0
    }
.text_ {
    color: currentColor;
    line-height: 1.2
    }
.text_1 {
    color: currentColor;
    font-weight: bold;
    line-height: 1.2
    }
.text_2 {
    color: #0563C1;
    line-height: 1.2;
    text-decoration: underline
    }

