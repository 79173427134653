.Disclaimer{
    border: 1px dashed #333436;
    border-radius: 0.5em;
    padding: 24px;

    h5{
        color: white;
        text-align: center;
        letter-spacing: -.02em;
        
        margin-bottom: 0.84375rem;
        margin-top: 0;

        font-size: 1.0625rem;
        font-weight: 600;

        font-family: 'Gothic A1', sans-serif;
    }

    h6{
        color: white;
        margin: 0;
        letter-spacing: -.02em;
        font-size: 10pt;
        margin-bottom: 0.5em;
    }

    p{
        margin-top: 0;
        letter-spacing: 0.01em;
        
        line-height: 1.5em;
        color: #6e84a3;
        font-size: .8rem;
        font-weight: 500;
        font-family: 'Gothic A1', sans-serif;
    }


}