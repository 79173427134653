.totalSection{

    width: calc(50% - 1em);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    height: auto;

    .totalsHeader{
        display: flex;
        height: 35pt;
        
        align-items: center;
        margin-bottom: 1em;

        .headerIcon{
            height: 28pt;
            margin-right: 1em;
            box-shadow: 0px 8px 16px rgb(226, 226, 226);
            border-radius: 56pt;
        }

        .headerText{   
            font-family: 'gotham-bold';
            font-style: normal;
            font-weight: 600;
            font-size: 28pt;
            color: rgb(191, 191, 191) !important;
            line-height: 35pt;
        }
    }

    .totalContent{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
}

@media(max-width: 900px){
    .totalSection{
        margin-top: 3em;
        flex-direction: column;
    };
}


@media (max-width: 1440px){
    .totalSection{
        .totalsHeader{
            display: flex;
            height: 28pt;
            
            align-items: center;
            margin-bottom: 1em;
    
            .headerIcon{
                height: 24pt;
                margin-right: 1em;
                box-shadow: 0px 8px 16px rgb(226, 226, 226);
                border-radius: 28pt;
            }
    
            .headerText{   
                font-family: 'gotham-bold';
                font-style: normal;
                font-weight: 600;
                font-size: 22pt;
                line-height: 28pt;
            }
        }
    }
}

@media (max-width: 1000px) and (orientation: portrait){
    .totalSection{
        width: 100%;
    }
}
