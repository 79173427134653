.statsCard {
    background: rgb(29, 29, 29);
    padding: 2em;
    padding-top: 1.8em;
    padding-bottom: 1.8em;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 1em;

    background: rgb(29, 29, 29);
    /* Block */

    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    .statsTitle {
        display: flex;

        font-family: 'gotham-book';
        font-style: normal;
        font-weight: 600;
        font-size: 12pt;
        color: rgb(191, 191, 191) !important;
        width: auto;
        justify-content: flex-start;

        .text{
            width: fit-content;
            overflow: hidden;
            text-overflow: clip;
            white-space: nowrap;
        }
        
        .titleIcon {
            height: 100%;
            margin-left: 1em;
            // box-shadow: 0px 8px 16px rgb(226, 226, 226);
            border-radius: 100%;
        }
    }

    .contents {
        text-align: right;
        .val {
            font-family: "gotham-bold";
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
            /* identical to box height */

            text-align: right;

            color: rgb(191, 191, 191) !important;
        }

        .subvalue {
            font-family: "gotham-book";
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            /* identical to box height */
            text-align: right;
            color: rgb(191, 191, 191) !important;
            opacity: 0.5;
        }

        .actionButton {
            background: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
            border-radius: 4px;
            color: white;
            border: none;
            height: 3em;
            
            font-size: 12pt;
            font-family: "DM Sans", sans-serif;
            padding-left: 2em;
            padding-right: 2em;
        }

        .actionButton:disabled{
            background: linear-gradient(180deg, #70ccfa 0%, #acf4fd 100%);        
        }
    }
}

@media (max-width: 820px){
    .statsCard{

        .statsTitle {
            max-width: 50%;
            justify-content: flex-start;
            align-items: center;
            .titleIcon {
                height: 100%;
                margin-left: 0.5em;
            }
        }
        .contents{
            .val{
                font-size: 18pt;
            }

            .actionButton {
                padding-left: 0em;
                padding-right: 0em;
                
                height: 4em;
                
            }
        }

        
    }
}

@media (max-width: 1440px) {
    .statsCard{
        .statsTitle {

            font-size: 10pt;

        }

        .contents{
            .val{
                font-size: 18pt;
            }
        }
    }
}

@media(max-width: 1000px) and (orientation: portrait){
    .statsCard{
        .statsTitle{
            width: 60%;
            font-size: 10pt;
            div{
                width: 7em;
            }
        }
    }
}