.TierPage {
    header {
        min-height: 35vh;
        display: flex;

        justify-content: center;
        flex-direction: column;

        h1 {
            color: white;
            font-family: 'Figtree', sans-serif;
            font-size: 3.6vw;
            letter-spacing: 0.03em;
        }
        p {
            color: white;
            font-family: 'Figtree', sans-serif;
            font-size: 20pt;
        }
    }

    section {
        -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
        -o-animation: fadein 2s; /* Opera < 12.1 */
        animation: fadein 2s;

        h1 {
            font-family: "Cabin", sans-serif;
            font-weight: 900;
            margin-bottom: 0.2em;
        }

        p {
            margin-top: 0;
            font-family: "Cabin", sans-serif;
            font-size: 1.45em;
        }

        ul {
            li {
                font-family: "Cabin", sans-serif;
                font-size: 1.4em;
            }
        }
        margin-bottom: 2.5em;

        .tierTableWrapper {
            display: flex;
            justify-content: center;
            margin-bottom: 2em;

            .tierTable {
                padding-top: 0.8em;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: fit-content;
                min-width: 35em;
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
                border-radius: 5px;
                background-color: rgb(251, 253, 253);

                h1 {
                    margin-bottom: 0.5em;
                    font-size: 23pt;
                }
                table {
                    width: 100%;

                    tr {
                        th {
                            font-family: "Cabin";
                            font-weight: 900;
                            font-size: 15pt;
                            padding-left: 0%;
                            text-align: center !important;
                        }

                        .picth {
                            padding: 0;
                        }

                        td {
                            text-align: center !important;
                            padding-left: 0%;

                            font-family: 'Figtree', sans-serif;
                            font-weight: 600;
                            font-size: 18pt;
                            color: rgb(28, 128, 175);

                            .tierName {
                                img {
                                    height: 4em;
                                }

                                p {
                                    font-weight: 400;
                                    font-size: 1.2rem;
                                    font-family: 'Figtree', sans-serif;
                                    color: black;
                                    margin-bottom: 0;
                                    margin-top: -0.8em;
                                    margin-bottom: 1em;
                                }
                            }
                        }

                        .pictd {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Firefox < 16 */
    @-moz-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Internet Explorer */
    @-ms-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Opera < 12.1 */
    @-o-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    margin-bottom: 10em;
}

@media (max-width: 1000px) and (orientation: portrait) {
    .TierPage {
        header {
            h1 {
                font-size: 36pt;
            }
        }

        section {
            .tierTableWrapper {
                .tierTable {
                    border-radius: 0;
                    min-width: 100vw;
                }
            }
        }
    }
}
