.withdrawCard {

        height: 100%;
        .confirmationButton {
            display: flex;

            .withdrawButton {
                background: linear-gradient(90deg, rgb(218 176 98) 0%, rgb(207 136 59) 100%) border-box;
                border-radius: 10px;
                color: white;
                border: none;
                height: 3em;
                width: 100%;
                font-size: 20px;
                font-weight: 500;
                font-family: 'gotham-bold';
                cursor: pointer;
                transition: 0.3s;
            }

            .withdrawButton:hover {
                box-shadow: 0px 0px 10px rgba(244, 174, 116, 0.452);
            }

            .harvestButton {
                
                margin-left: 1rem;
                background: linear-gradient(90deg, rgb(78 159 82 / 74%) 0%, rgb(175 205 139 / 97%) 100%) border-box;
                border-radius: 10px;
                color: white;
                border: none;
                height: 3em;
                width: 100%;
                font-size: 20px;
                font-weight: 500;
                font-family: 'gotham-bold';
                cursor: pointer;
                transition: 0.3s;

                // .whiter {
                //     background-color: white;
                // }

                .gradientText {
                    // background-image: linear-gradient(180deg, #0aa7f5 0%, #3ce7ff 100%);
                    // background-clip: text;
                    color: white;
                }
                cursor: pointer;
            }

            .harvestButton:hover {
                box-shadow: 0px 0px 10px hsla(147, 92%, 75%, 0.452);
            }
        }

        .cardHeader {
    
            .headerIcon {
                height: 100%;
                margin-right: 1em;
                box-shadow: 0px 8px 16px rgb(226, 226, 226);
                border-radius: 28pt;
            }
    
            .headerText {
    
                font-weight: 400;
                padding: 5px;
                font-size: 32px;
                line-height: 39px;
                color: rgb(40, 40, 40) !important;
            }
            margin-bottom: 1.5em;
        }
    
        .cardContent {
         
            
            input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .input {
        margin-bottom: 1.5em;
      
        .inputFields {
          display: flex;
          flex-direction: column;
      
          .inputField {
            padding-left: 15px; /* Added margin-left property */
            background: #cfcfcf94;
            border: none;
            outline: none;
            border-radius: .625rem !important;
            color: rgb(59, 59, 59);
            height: 3em;
            font-size: 14pt;
            font-weight: 600;
            box-shadow: 0 0 0 rgba(219, 154, 57, 0.265);
            transition: box-shadow 0.3s ease-in-out;
          }
      
          .inputField:focus {
            background: #dddddd94;
          }
      
          .inputFieldPostpend {
            background: rgba(0, 0, 0, 0.05);
            border: none;
            border-radius: 0px 4px 4px 0px;
            color: rgb(46, 46, 46);
            text-align: center;
            margin-left: 10px;
            font-size: 14pt;
            height: 3em;
            width: 20%;
          }
        }
      
      
                .inputHeader {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
    
                    .headerBalance {
                  
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12pt;
                        color: rgb(48, 47, 47) ;
                        line-height: 20pt;
                    }
    
                    .headerMax {
                        background: linear-gradient(90deg, rgb(43 43 43 / 87%) 0%, rgb(29 28 27 / 73%) 50%) border-box;
                        border-radius: 80px;
                        border: none;
    
              
                        font-style: normal;
                        font-weight: 500;
                        font-size: 8pt;
    
                        width: 48px;
                        height: 15pt;
    
                        color: #ffffff;
                        cursor: pointer;
                    }
                }
            }
    
            .percentSlider{
                margin-top: 1em;
                margin-bottom: 1em;
            }
    
    
    
            .confirmationButton {
                justify-content: center;
            
                
                .sideBySideButton {
                    display: inline-block;
                    background: linear-gradient(90deg, rgb(218 176 98) 0%, rgb(207 136 59) 100%) border-box;
                    border-radius: 10px;
                    padding-top: 5px;
                    color: white;
                    border: none;
                    height: 3em;
                    width: 100%;
                    margin-bottom: 5px;
                    font-size: 20px;
                    font-weight: 500;
                    font-family: 'gotham-bold';
                    cursor: pointer;
                    transition: 0.3s;
                }
                .sideBySideButton:disabled {
                    opacity: 0.5;
                    background: rgb(228, 194, 108);
                    cursor: not-allowed;
                  }
                .stakeButton {
                    background: linear-gradient(90deg, rgb(218 176 98) 0%, rgb(207 136 59) 100%) border-box;
                    border-radius: 10px;
                    padding-top: 5px;
                    color: white;
                    border: none;
                    height: 3em;
                    width: 100%;
                    font-size: 20px;
                    font-weight: 500;
                    font-family: 'gotham-bold';
                    cursor: pointer;
                    transition: 0.3s;
                }
    
                .stakeButton:hover{
                    box-shadow: 0px 0px 10px rgba(244, 174, 116, 0.452);
                }
            }
        }
    }


@media (max-width: 1000px) and (orientation: portrait){
    .withdrawCard{
        width: 100%;
        .cardHeader{
            .headerText{
                font-size: 19pt;
            }
            margin-bottom: 0.5em;

        }

        .cardContent{
            box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
        }
    }
}