

.valuePriceCard{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Set the height to 100% of the viewport height */
    margin: 0 5%;

    .verticalSplitter{
        width: 1px;
        border-left: 1px solid rgb(231, 231, 231);
        height: 50%;
    }
    .alertBanner {
        background-color: #f8d7da;
        color: #721c24;
        padding: 10px;
        font-weight: bold;
        text-align: center;
        border-radius: 4px;
      }
      

    .subsection{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;

        .iconDiv{
            display: flex;
            align-items: center;
            height: 100%;

            .icons{
                height: 65%;   
            }
        }

        .subsectionTitle{
            font-family: 'gotham-bold';
            font-size: .875rem;
            color: rgb(65, 65, 65);
            margin-bottom: 9px;
            font-weight: 600;
            line-height: 1.25rem;
        }

        .subsectionContent{
            font-family: 'gotham-bold';
            font-style: normal;
            font-weight: 400;
            line-height: 2rem;
            font-size: 26px;
            /* identical to box height */
            color: #000000;
        }
    }
    
}


@media (max-width: 1440px ) and (min-width: 1000px){
    .valuePriceCard{
        height: 6em;
        width: 32vw;
        // min-width: 32vw;

        .subsection{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 100%;
    
            .iconDiv{
                display: flex;
                align-items: center;
                height: 100%;
    
                .icons{
                    height: 65%;   
                }
            }
    
            .subsectionTitle{
                font-family: 'Figtree', sans-serif;
                font-size: 10pt;
                color: rgb(255 255 255/1);
            }
    
            .subsectionContent{
                font-family: 'Figtree', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 20pt;
                /* identical to box height */
                color: rgb(255 255 255/1);
            }
        }
    }
}

@media (max-width: 1000px) and (orientation: portrait) {
    .valuePriceCard{
        width: 100%;
        max-width: 100%;
        height: 10vh;
        min-width: 32vw;
        .subsection{


            .subsectionTitle{
                font-family: 'Figtree', sans-serif;
                font-size: 12pt;
                color: rgb(255 255 255/1);
            }
    
            .subsectionContent{
                font-family: 'Figtree', sans-serif;
                font-style: normal;
                font-weight: 800;
                font-size: 14pt;
                /* identical to box height */
                color: rgb(255 255 255/1);
            }
        }
    }
}
