
    h1{
        font-family: 'Cabin', sans-serif;
        color: rgb(92, 92, 92);
        margin: 0px;
        display: flex;
        height: 56px;
        flex-direction: column;
        justify-content: center;
        margin-right: 15px;
    }

    .formRow{
        display: flex;
        margin-bottom: 1em;
        margin-top: 1em;
    }

    hr{
        width: 100%;
    }

    .button{
        background: linear-gradient(180deg, #0AA7F5 0%, #3CE7FF 100%);
        border-radius: 4px;
        font-style: normal;
        font-weight: 500;
        width: 180px;
        height: 48px;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        border: 0px;
        margin-right: 16px;
        margin-top: 10px;
        font-weight: bold;
    }

    .textOutputs{
        font-family: "Cabin";
        font-size: 14pt;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.1em;
        border-bottom: 1px solid rgb(235, 235, 235);
        background-color: rgba(255, 255, 255, 0.561);
        padding: 10px;
        border-radius: 10px;
        // box-shadow: 0px 0px 15px rgba(139, 139, 139, 0.233);
        transition: 1s;
        margin-bottom: 10px;

    }