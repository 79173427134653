.dialog{
    
    .dialogHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.2rem;

    
        .closeIcon {
            cursor: pointer;
            display: flex;
            position: relative;
            text-align: left;
            justify-content: center;
            align-items: center;
            font-size: 2em;
            background-color: rgba(0, 0, 0, 0.06);
            width: 28px;
            height: 28px;
            border-radius: 9999px;
            color: rgb(177, 177, 177);
        }

        .closeIconsvg {
            cursor: pointer;
            font-size: 2em;
            color: rgb(177, 177, 177);
        }
    }
        /* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
    .walletInfo {
        display: flex;
        align-items: center;
        border-radius: 10px;
        background: rgb(72,63,251);
        background: #000000;
        padding: 0.25em;
        padding-left: 1em;
        margin-bottom: 1em;
        
        

        .walletIconWrapper{
            width: 10%;
            .walletIcon {
                padding: 10px;
                background-color: white;
                color: rgb(0, 89, 131);
                border-width: 0px;
                border-radius: 1em;
                font-size: 28pt;
            }
        }
    
        .infoContainer{
            color: white;
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 90%;
            
            
            .infoItem{
                
                h3{
                    font-size: 0.875rem;
                   
                    margin-bottom: 9px;
                    font-weight: 600;
                
                }

                p{
                    margin-top: 0.5em;
                    font-size: 0.975rem;
                   
                    margin-bottom: 9px;
                    font-weight: 600;
                   
                }
            }
        }
    
        
    
    }
    
    
    .addressField{
        margin-bottom: 1em;
    }

    .width {
        width: 100%;
        display: flex;
justify-content: center;
align-items: center;

    }
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        gap: 8px;
        margin-top: 16px;
        margin-right: 2px;
        margin-left: 2px;
        margin-bottom: 2px;
        
        
        .element{
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            box-shadow: 0px 2px 6px rgba(37, 41, 46, 0.04);
            padding-bottom: 8px;
            padding-left: 8px;
            padding-top: 8px;
            padding-right: 8px;
            background-color: #f7f7f7;
            border-radius: 14px;
            
        }
    }
    
}

@media (max-width: 1000px) and (orientation: portrait){
    .dialog{
        .walletInfo{
            flex-direction: row-reverse;
            .walletIconWrapper{
                width: 35%;
                .walletIcon{
                    width:100%;
                    height: 100%;
                }
            }

            .infoContainer{
                flex-direction: column;
                align-items: flex-start;
                max-width: 60%;
                
            }
        }
    }
}