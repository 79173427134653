.dialog {
    .dialogTitle{
        text-align: center;
        margin: 0;
    }

    .actions {
        display: flex;
        justify-content: center;

        margin-bottom: 1em;
       
        .closeButton {
            display: inline-block;
            background: linear-gradient(90deg, rgb(218, 176, 98) 0%, rgb(207, 136, 59) 100%) border-box;
            border-radius: 10px;
            padding-top: 5px;
            color: white;
            border: none;
            height: 3em;
            width: 50%;
            margin-bottom: 5px;
            font-size: 20px;
            font-weight: 500;
            font-family: "gotham-bold";
            cursor: pointer;
            transition: 0.3s;
        }
        
        .closeButton:active{
            background: linear-gradient(90deg, rgb(224, 200, 154) 0%, rgb(175, 134, 89) 100%) border-box;
            opacity: 0.8;
        }
    }
}
