.totalPanel {
    background-color: cadetblue;
    width: auto;
    background: rgb(29, 29, 29);
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Block */

    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    min-height: 5em;
    margin-bottom: 1em;
    padding: 2em;
    height: 100%;

    .header {
        display: flex;
        .title {
            font-family: 'gotham-book';
            font-style: normal;
            font-weight: 600;
            font-size: 12pt;
            color: rgb(191, 191, 191) !important;
            
        }

        .infoIcon{
            box-shadow: 0px 8px 16px rgb(226, 226, 226);
            border-radius: 28pt;
            margin-left: 0.75em;
        }
        margin-bottom: 0.5em;
    }

    .body {
        .value {
            background-image: linear-gradient(180deg, rgb(237, 178, 90) 0%, #b67438 100%);
            background-clip: text;
            color: transparent;

            font-family: 'gotham-bold';
            font-style: normal;
            font-weight: 600;
            font-size: 24pt;
            line-height: 30pt;

            .valueSubappend {
                font-size: 14pt;
            }
        }

        .subvalue {
            font-family: 'gotham-book';
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: rgb(191, 191, 191) !important;
            /* identical to box height */

            color: #000000;

            opacity: 0.5;
        }
    }
}

@media(max-width: 820px){
    .totalPanel{
        width: auto;
        margin-bottom: 1em;
    }
}

@media(max-width: 1440px){
    .totalPanel{
        .header{
            
        }

        .body{
            .value{
                font-size: 23pt;
            }
        }
    }
}