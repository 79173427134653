.Table{
    overflow-x: auto;
    background: #FFFFFF;
    box-shadow: 0px -2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-top: 16px;

    .line{
        background: #000000;
        opacity: 0.1;
        border-radius: 4px;
        height: 2px;
    }
}