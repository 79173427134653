.tableBody{
    background: #FFFFFF;
    box-shadow: 0px -2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 0px 4px 4px 4px;
    padding: 16px 48px 48px 48px;
}

.detailTable{
    margin-bottom: 50px;
    .aboutTheProject{
        padding: 1em;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.089);
        border-radius: 0px 20px 20px 20px;
    }
}

@media (max-width: 1440px) {
    .detailTable{
        .controlButtons{
            
        }
    }
}

@media (max-width: 1000px) and (orientation: portrait){ 
    .detailTable{
        .controlButtons{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}
