.collapsibleContent{
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(139, 139, 139, 0.233);
    margin-bottom: 10px;
}

.collapsibleHeader {
    font-family: "Cabin";
    font-size: 36pt;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.1em;
    border-bottom: 1px solid rgb(235, 235, 235);
    background-color: rgba(255, 255, 255, 0.561);
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(139, 139, 139, 0.233);
    transition: 1s;
    margin-bottom: 10px;

}

.collapsibleHeader {
    transition: 1s;

}

.collapsibleHeaderisOpen {
    background-color: white;
    font-family: "Cabin";
    font-size: 36pt;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.1em;
    border-bottom: 1px solid rgb(235, 235, 235);
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(139, 139, 139, 0.233);
    transition: 1s;
    margin-bottom: 10px;

}

.collapsibleHeaderisOpen svg {
    transform: rotate(180deg);
    transition: 1s;
}

.sectionHeader {
    border-bottom: 1px solid rgb(158, 158, 158);
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
        font-family: "Cabin";
    }
}