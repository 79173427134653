.Info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 20px;
  margin: 0 5%;
  margin-top: 5vh;

  @media screen and (max-width: 768px) {
    /* Apply the styles below only when the screen width is 768px or smaller */
    margin: 0 3%; /* Adjust the margin for smaller screens */
  }

.cardsRow {
  display: flex;
  flex-direction: row;

}
@media screen and (max-width: 768px) {
  .cardsRow {
    flex-direction: column;
  }
}

.timerContainer {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background-color: #f0f0f0;
}

.timerText {
  font-size: 18px;
  margin: 0;
}

.timerDigit {
  font-size: 24px;
  font-weight: bold;
  color: #d39e5e; /* or any color you prefer */
}


.cardFooter {
  
  margin-left: 15px;
  margin-right: 15px;
  z-index: 1000; /* Adjust the z-index as needed */
  background-color: #fff; /* Add a background color if desired */
  padding: 5px; /* Add padding for spacing */
  border-top: 1px solid #ddd; /* Add a border at the top for separation */
  @media (max-width: 768px) {
    margin-bottom: 23px;
    margin-left: 15px;
    margin-right: 15px;
    z-index: 1000; // Adjust the z-index as needed
  }
}
.apr {
  margin-top: 10px;
  text-align: left;
  font-size: 45px;
  font-weight: 500;
}

.note {
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
}

.stakenote {
  margin-bottom: 10px;
  margin-top: 2px;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  width: 100%; /* Updated to full width for mobile */
  max-width: 600px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  /* Apply the styles below only when the screen width is 768px or larger (adjust as needed) */
  .cardContainer {
    width: 50%;
  }
}


  .featureCards {
    grid-gap: 20px;
    display: flex;
    flex-direction: column;

    .leftText {
      max-width: 350px;
      padding: 20px;
      padding-right: 15vh;
      line-height: 1.25;
      color: rgb(226, 224, 224);
      border-radius: 24px;
      color: rgb(0, 0, 0);
      background: rgb(255, 255, 255);
      padding: 12px 8px 8px;
      border: 1px solid #f0f0f0;

      h2 {
        font-size: 35px;
        font-weight: bold;
        margin-bottom: 30px;
      }

      p {
        font-size: 20px;
        color: #b0b0b0;
      }
    }

    .rightCards {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px;
      padding: 20px;

      .featureCard {
        background-image: linear-gradient(rgba(10, 10, 13, 0) 48%, rgba(10, 10, 13, 0.01) 56%, rgba(10, 10, 13, 0.88)),
          linear-gradient(#16141a, #0f0d13 80%);
        border-radius: 4px;
        box-shadow: 0 1px rgba(255, 255, 255, 0.05), -1px 0 rgba(255, 255, 255, 0.08),
          1px 0 rgba(255, 255, 255, 0.08), 0 -1px rgba(255, 255, 255, 0.12);
        border-radius: 8px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        letter-spacing: 0.4px;
        max-width: 280px;
        font-size: 1rem;
        line-height: 1.4;

        .imgBlock {
          width: 100px;
          height: 100px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .title {
          font-size: 25px;
          font-weight: bold;
          margin-top: 10px;
          margin-bottom: 20px;
        }

        .text {
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .featureCards {
      flex-direction: row;

      .card,
      .newCard {
        width: calc(100% - 20px);
        margin-right: 20px;
        padding-bottom: 10px;
      }

      .historyCard {
        width: calc(50% - 20px);
      }
    }
  }
}

.tokenfund {
  font-size: 18px;
  font-weight: 600;
}

.card {
  border: none;
  border-radius: 10px;
  position: relative;
  width: 100%; /* Adjust width to take full width */
  max-width: 800px;
  margin-bottom: 20px;
  background-color: #ffffff;

  .d-flex {
    display: flex;
  }

  
  .justify-content-between {
    justify-content: space-between;
  }

  .flex-row {
    flex-direction: row;
  }

  .align-items-center {
    align-items: left;
  }

  .ms2 {
    margin-left: 2rem;
  }

  .ms3 {
    margin-left: 2rem;
    font-size: 20px;
    font-weight: 600;

  }


  .flexRow {
    flex-direction: row;
  }

  .alignItemsCenter {
    align-items: center;
  }

  .cDetails {
    h6 {
      margin-bottom: 15px;
      margin-top: 15px;
    }

    .cDetailsText {
      font-weight: 600;
      font-size: 45px;
      text-align: left;
    }
  }

  .badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #000000;
    height: 25px;
    padding-bottom: 3px;
    border-radius: 25px;
    display: flex;
    color: #ffffff;
    padding: 8px;
    justify-content: center;
    align-items: center;
  }

  .mt5 {
    margin-top: 5px;
  }
  .mt6 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    padding: 15px;
    font-size: 20px;
    font-weight: 600;
  }
  
  .button {
    background-color: #000;
    border-radius: 4px;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;

    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    margin: 0;
    max-width: none;
    min-height: 44px;
    min-width: 10px;
    outline: none;
    overflow: hidden;
    padding: 9px 20px 8px;
    position: relative;
    text-align: center;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
    &:hover {
      opacity: .75;
    }
    &:focus {
      opacity: .75;
    }
  }
  .flexColumn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }
    
  .mb0 {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
  }

  .mb1 {
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
  }

  .mb2 {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    color:#969393
  }
/* YourComponent.module.css */

.cardWithBackground {
  background: url("data:image/svg+xml;base64,PHN2ZyBpZD0iYmZiZWFkYzktODNiMS00ZjZiLWI3NzAtYjNhMmRjZGU5NzYzIiBkYXRhLW5hbWU9IkNhbHF1ZSAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjUiIHdpZHRoPSIyNSIgdmlld0JveD0iMCAwIDM0OS45IDQ2My4xNSI+PHBhdGggY2xhc3M9ImIxZWY0ZDczLTZkOTQtNDAwYi1iM2Q3LTQ5MGFlOGIxYTc0YSIgZD0iTTE5LjMxLDE4LjQzYzI2LjI1LDI4LjA3LDU0Ljg3LDU0LjgsNzkuMzksODQuMDUsMjYuODksMTIuOSwzMy44MiwxNy43Mi4xMS4zNCwzMS4yMyw1OS43NCw2MS43MSwwLDE1LjQzLDE4LjYsMzAuMjEsMjcuNSwyNiw4Mi43Myw4My43Myw4Ni43My0xNC41NiwxNy40OC0yLjM5LDMuMi0zMy40MywyMS43Mi0yLjI0LDEyLjQwLDU4LjYzLDExMC4yOCw4MC41NiwyOC43OSw4NjcuNzMsMzMuODMsODMuODQsNTMuMjIsNzkuNDAxLDM3LjcyLDg2LjczLDY3LjYzLDE0LjY4LDIwLjE5LDIwLjczLDI3LjU5LDIzLjAsNjMuNzcsMTI2LjIsMTMwLjg4LDg2LjEsMTg5LjI0LDE5LjM5LDIwLjE5LDE4LjU0LDIzLjYsMTc3LjksMTUuMywxNi45OCwyMi4wNSwyNC43OSw1Ny4xNiwyLjMwLDM5LjE1LDE3Ny42OSwyOC4wOS4wNCwxOC4wMy40OS01LjI5LDE5LjA4LTQuOTcsMjcuOS0yOS41LDQ4LjgtMjkuNzksODkuMDksODMuNzMsODIuOTQsNTMuMjIsNTYuMzksNjIuMTMsODYuNzMsMTA0LjU4LDEzLjI0LDE0NC4wOS44Mi0yLDEzLjIyLTI5LjQ0LDI2LjU3LTExNi43MywyMzEuNC0yOC43NywxNS43LDc5Ljc5LDkwLjQ3LDAsMTguMCwtMy40MywxNS44LDAuNCwxOS41LDAuMDUsMjIwLjg0LDIyLjY0LDE5Ni42LDE0LjUgMTk4LjMsMTgyLjUyLDE1Mi4zLDE4LjMsMTcyLjIgMTg2LjksMTcxLjYyLDIwLjE5LDE3Ny41LDE3My44MiwyMy4wLDE5OS43LDE4LjMyLDE2Mi4wMl0iIHRyYW5zZm9ybT0idHJhbnNmZXJyZWQiIHRvcG1hcmdpbj0iMCAwIDM0OS45IDQ2My4xNSI+PC9wYXRoPjwvc3ZnPjwvc3ZnPjwvc3ZnPgo=") center/cover no-repeat;
}


  .dFlex {
    display: flex;
  }

  .justifyContentBetween {
    justify-content: space-between;
  }

  .heading {
    font-size: 1.5rem;
    font-weight: bold;

    br {
      display: none;
    }
  }

  .progress {
    height: 10px;
    border-radius: 10px;

    .progress-bar {
      background-color: red;
    }
  }

  .mt3 {
    margin-top: 3px;
    padding: 0 60px;
  
    @media screen and (max-width: 768px) {
      /* Apply the styles below only when the screen width is 768px or smaller */
      padding: 0; /* No padding for smaller screens */
    }
  }
  

  .text1 {
    font-size: 14px;
    font-weight: 600;
  }

  .text2 {
    color: #a5aec0;
  }
}


.blackcard {
  border: none;
  border-radius: 10px;
  position: relative;
  width: 100%; /* Adjust width to take full width */
  max-width: 800px;
  margin-bottom: 20px;
  background-color: #edcca6b8;
  margin-right: 20px;
  .d-flex {
    display: flex;
  }

  
  .justify-content-between {
    justify-content: space-between;
  }

  .flex-row {
    flex-direction: row;
  }

  .align-items-center {
    align-items: left;
  }

  .ms2 {
    margin-left: 2rem;
  }

  .flexRow {
    flex-direction: row;
  }

  .alignItemsCenter {
    align-items: center;
  }

  .cDetails {
    h6 {
      margin-bottom: 15px;
      margin-top: 15px;
    }

    .cDetailsText {
      font-weight: 600;
      font-size: 45px;
      text-align: left;
    }
  }

  .badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #000000;
    height: 25px;
    padding-bottom: 3px;
    border-radius: 25px;
    display: flex;
    color: #ffffff;
    padding: 8px;
    justify-content: center;
    align-items: center;
  }

  .mt5 {
    margin-top: 5px;
  }

  .mb0 {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
  }

  .mb1 {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
  }

  .dFlex {
    display: flex;
  }

  .justifyContentBetween {
    justify-content: space-between;
  }

  .heading {
    font-size: 1.5rem;
    font-weight: bold;

    br {
      display: none;
    }
  }

  .progress {
    height: 10px;
    border-radius: 10px;

    .progress-bar {
      background-color: red;
    }
  }

  .mt3 {
    margin-top: 3px;
  }

  .text1 {
    font-size: 14px;
    font-weight: 600;
  }

  .text2 {
    color: #a5aec0;
  }
}


.stakecard {
  border: none;
  border-radius: 10px;
  position: relative;
  width: 100%; /* Adjust width to take full width */
  max-width: 800px;
  margin-bottom: 20px;
  background-color: rgb(213 160 82);
  margin-right: 20px;
  overflow: hidden;
}

.ms2 {
  margin-left: 2rem;
}

.cDetails h6 {
  margin-bottom: 15px;
  margin-top: 15px;
}

.cDetails p {
  margin: 0.5rem 0 1rem;
}

button {
  background-color: white;
  color: black;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  cursor: pointer;
}



@media (max-width: 768px) {
  .card {
    width: 100%;
    max-width: none;
  }
}

@media (max-width: 600px) {
  .mt3 {
    padding: 0 8px;
  }
}



.cardWithBackground {
  padding: 20px;
  border-radius: 15px;
  color: white;
  position: relative;
  overflow: hidden;
}

.mb0 {
  margin-bottom: 0;
}

.mb2 {
  margin-bottom: 2rem;
}

.apr {
  font-size: 1.5rem;
  font-weight: bold;
}



button {
  background-color: white;
  color: black;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  cursor: pointer;
}