.loginPage{
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .loginCard{
        position: absolute;
        
        min-width: 30vw;
        h1{
            margin-top: 0;
        }
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.24);
        padding: 2em;
        padding-top: 0;
        padding-bottom: 0.5em;
        background-color: white;
        border-radius: 10px;

        .input{
            margin-bottom: 1em;
        }

        footer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                color: red;
                margin: 0;
            }



        }
    }
}