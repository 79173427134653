.formPanel{
    background-color: white;
    border-radius: 10px;
    padding: 1em;
    width: 90%;
    box-shadow: 0px 0px 10px rgba(139, 139, 139, 0.452);

    h1{
        font-family: 'Cabin', sans-serif;
        color: rgb(92, 92, 92);
        margin-top: 15px;
    }

    .formRow{
        display: flex;
        margin-bottom: 1em;
        margin-top: 1em;
        align-items: center;
    }

    hr{
        width: 100%;
    }
}
